export default {
    attachResize: ->
        $content = $('.page-content')
        $body = $('body')
        $navbar = $('.navbar')
        $subNav = $(".sub-nav")
        $alert = $('.block-alert-container')

        return if !$navbar

        adjustContentDimensions = ->
            currentHeight = $content.height()
            newHeight = $body.height() - ($content?.position()?.top || 0)
            if currentHeight != newHeight
                $content.css('height': "#{newHeight}px")

        $(window).resize(adjustContentDimensions)
        adjustContentDimensions()
}
