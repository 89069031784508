export class FlashMessageView extends Backbone.View
    tagName: "div"

    className: "block-alert alert"

    template: _.template("<%= content %><a class='close' href='#'>×</a>")

    defaults: {
        type: "success",
        hideAfter: 7000,
        category: "main",
        unique: false
    }

    events:
        "click .close": "destroy"

    initialize: (@options = {}) ->
        @options = _.extend({}, @defaults, @options)

    render: ->
        $el = @$el.html(@template(content: @options.content))
        $el.addClass(@options.type)

        containerElem = $(".block-alert-container")
        containerElem.append($el)

        $el.slideDown(200)

        _.delay(_.bind(@destroy, @), @options.hideAfter) if @options.hideAfter > 0
        @

    destroy: (e) ->
        e.preventDefault() if e
        FlashMessage.remove(@)
        @$el.slideUp(200, => @remove())

    category: ->
        @options.category

export class FlashMessage
    @messages: []

    constructor: (content, options) ->
        options = _.extend({}, {content: content}, options)
        if options.unique
            @removeByCategory(options.category)

        @add(new FlashMessageView(options).render())

    add: (messageView) ->
        FlashMessage.messages.push(messageView)

    @remove: (messageView) ->
        FlashMessage.messages = _.without(FlashMessage.messages, messageView)

    removeByCategory: (category) ->
        message.destroy() for message in _.filter(FlashMessage.messages, (message) -> message.category() == category)

    @deleteByCategory: (category) ->
        message.destroy() for message in _.filter(FlashMessage.messages, (message) -> message.category() == category)
