require("es6-promise").polyfill();
require("universal-url").shim();
require("libs/enable_timer.coffee");

let textEncode = require("@sinonjs/text-encoding");
window.TextDecoder = window.TextDecoder || textEncode.TextDecoder;
window.TextEncoder = window.TextEncoder || textEncode.TextEncoder;

window._ = require("underscore");
window.Backbone = require("backbone");
window.Backbone.$ = window.$;

// Check at some point do we need this library anymore. This was added because
// of JS rounding errors in foundF handlebar helper.
window.Decimal = require("decimal.js");

window.Handlebars = require("handlebars");
window.moment = require("moment/min/moment-with-locales.min");
window.dayjs = require("dayjs");
window.FlashMessage = require("libs/flash_message.coffee").FlashMessage;
window.PhoneNumberView = require("views/phone_number_view.coffee").default;
require("libs/list");
require("views/nagger");

window.ifvisible = require("ifvisible.js");
window.autosize = require("autosize");

$.fn.autosize = require("autosize");

require("vendor/bootstrap-datepicker-locales")($);
require("Flot");
require("vendor/jquery.flot/jquery.flot.entry.js.coffee");
require("Flot/jquery.flot.resize.js");
require("Flot/jquery.flot.time.js");
require("libs/bootstrap-combobox.coffee");

let viewport = require("viewport.coffee").default;

$(window).on("load", function () {
  viewport.attachResize();
});
