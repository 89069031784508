import intlTelInput from 'intl-tel-input'

export default class PhoneNumberView extends Backbone.View
    events:
        "countrychange": "render"
        "change": "showCorrectInstructionsAndPlaceholder"

    initialize: ->
        @iti = intlTelInput(@el,
            {preferredCountries: @$el.data('countries'), nationalMode: false})

    render: ->
        @countryData = @iti.getSelectedCountryData()
        @setCountryDataToInput()
        @showCorrectInstructionsAndPlaceholder()
        @iti._updateFlagFromNumber(@iti.telInput.value)

    setCountryDataToInput: ->
        $("input#countryCode").attr('value', @countryData.iso2)

    showCorrectInstructionsAndPlaceholder: ->
        return unless @countryData.dialCode

        $("#phone-guidance").html(
            I18n.t("js.phone.instructions", {
                country_code: @countryData.dialCode,
                placeholder: @phonePlaceHolder(),
                phonenumber: @phonePlaceHolder()
            })
        )
        $(".phone-number").attr(
            'placeholder',
            I18n.t("js.phone.placeholder", {
                country_code: @countryData.dialCode,
                placeholder: @phonePlaceHolder()
            })
        )

    phonePlaceHolder: ->
        if typeof(PhoneFormat) == "object" then PhoneFormat.placeholder else "41 123 45 67"

    phoneLocalNumber: ->
        if typeof(PhoneFormat) == "object" then PhoneFormat.localnumber else "041 123 45 67"
