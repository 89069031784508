class Combobox
    constructor: (elem, options = {}) ->
        @elem = $(elem)
        @options = options
        @css_classes = options.classes || ""
        source = @options.source || @elem.data("source")
        typeaheadOptions = @options.typeahead || {}
        _.defaults(typeaheadOptions, {source: source, minLength: 0})
        @typeahead = @elem.typeahead(typeaheadOptions).data("typeahead")
        @render()
        @bindEvents()

    render: ->
        @elem.wrap("<div class='input-append bootstrap-combobox #{@css_classes}'></div>").after('<button class="btn show-typeahead" type="button"><i class="fa fa-caret-down"></span></button>')

    showTypeahead: (e) =>
        @elem.focus()
        @typeahead.trigger()

    bindEvents: ->
        @elem.add(".show-typeahead").click(@showTypeahead)

$.fn.combobox = (options) ->
    return this.each(() ->
        new Combobox(this, options)
    )
