class List extends Backbone.View

    initialize: (options) ->
        @items = []
        @visibleItems = []
        @filterText = ""
        @currentPage = 0

        @searchEl = @$el.find(options.searchEl || ".input-search")
        @listEl = @$el.find(options.listEl || ".list")
        @emptyEl = @$el.find(options.emptyEl || ".empty-list")
        @headerEl = @$el.find(options.headerEl || ".header")

        @limit = options.limit || 0
        @searchFields = options.searchFields || []

        @template = options.template
        @headerTemplate = options.headerTemplate

        @pagination = new Pagination({el: options.paginationEl || ".paginate", list: @})

        @render()
        @search()

    update: ->
        @visibleItems = _.filter(@items, @filter, @)
        @pagination.render()
        @render()

    render: ->
        html = ""
        @emptyEl.hide()

        for item in @page(@visibleItems)
            html += @template(item)

        @emptyEl.show() if html.length == 0

        @listEl.html(html)
        @trigger("render")

    page: (items) ->
        if @limit > 0
            items.slice(@currentPage*@limit, (@currentPage+1)*@limit)
        else
            items

    add: (items) ->
        @items.push.apply(@items, items)

        @update()

    set: (items) ->
        @items = items
        @update()

    setHeaders: (headers) ->
        @headerEl.html(@headerTemplate(headers))

    filter: (item) ->
        for field in @searchFields
            return true if item[field] && item[field].toLowerCase().indexOf(@filterText.toLowerCase()) != -1

        return false

    search: ->
        @filterText = @searchEl?.val() || ""
        @currentPage = 0
        @update()

    events:
        "keyup .input-search": "search"

class Pagination extends Backbone.View

    initialize: (options) ->
        @list = options.list
        @render()

    currentPage: ->
        @list.currentPage

    limit: ->
        @list.limit

    count: ->
        @list.visibleItems.length

    isFirstPage: (page) ->
        page == 0

    isLastPage: (page) ->
        page == @pageCount()-1

    isCurrentPage: (page) ->
        page == @currentPage()

    pageCount: ->
        Math.ceil(@count() / @limit())

    render: ->
        html = ""

        if  @count() > @limit()
            prevShown = false

            html += @templatePrev(@isFirstPage(@currentPage()))

            for i in [0..(@pageCount()-1)]
                if @shouldRender(i)
                    html += @templateNumber(i+1, @isCurrentPage(i))
                    prevShown = true
                else
                    html += @templateDot() if prevShown
                    prevShown = false

            html += @templateNext(@isLastPage(@currentPage()))

        @$el.html(html)

    shouldRender: (page) ->
        @isFirstPage(page) || @isInRange(page, @currentPage(), 1) || @isLastPage(page)

    isInRange: (i, current, range) ->
        i >= (current-range) && i <= (current+range)

    changePage: (page) ->
        @list.currentPage = page
        @list.update()

    nextPage: ->
        @changePage(@currentPage()+1) unless @isLastPage(@currentPage())

    previousPage: ->
        @changePage(@currentPage()-1) unless @isFirstPage(@currentPage())

    setPage: (e) ->
        page = parseInt($(e.target).attr("data-page"))
        @changePage(page)

    templateNumber: (page, active) ->
        if active
            "<li class='active' data-page='#{(page-1)}'><a href='##{@list.$el.attr("id")}'>#{page}</a></li>"
        else
            "<li class='change-page'><a href='##{@list.$el.attr("id")}' data-page='#{(page-1)}'>#{page}</a></li>"

    templateNext: (disabled) ->
        if disabled
            "<li class='next disabled'><a href='##{@list.$el.attr("id")}'><i class='fa fa-chevron-right'></i></a></li>"
        else
            "<li class='next'><a href='##{@list.$el.attr("id")}'><i class='fa fa-chevron-right'></i></a></li>"

    templatePrev: (disabled) ->
        if disabled
            "<li class='previous disabled'><a href='##{@list.$el.attr("id")}'><i class='fa fa-chevron-left'></i></a></li>"
        else
            "<li class='previous'><a href='##{@list.$el.attr("id")}'><i class='fa fa-chevron-left'></i></a></li>"

    templateDot: ->
        '<li class="disabled"><a href="#">...</a></li>'
    events:
        "click .change-page": "setPage"
        "click .next": "nextPage"
        "click .previous": "previousPage"

window.List = List
