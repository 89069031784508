
# Maximum number of overlapping rows of entries
N_ROWS = 6
ROW_HEIGHT = 23

MIN_BAR_WIDTH = 5 # Entries shorter than this (in pixels) are shown as points
TEXT_PADDING = 3  # Space between texts and their bars or points
PADDING_BOTTOM = 10
BAR_HEIGHT = 8

PRIORITY_ENTRY_COLOR = [95,158,160]
PATIENT_ENTRY_COLOR = [203,150,193]
COMBO_ENTRY_COLOR = [128,128,128]

init = (plot) ->

    actualPlotWidth = (plot) ->
        plot.width() + plot.getPlotOffset().left

    visiblePart = (plot, xBegin, xEnd) ->
        xMin = plot.getPlotOffset().left
        xMax = actualPlotWidth(plot)
        return null if !(xMax >= xBegin && xMin <= xEnd)
        xBegin = xMin if xBegin < xMin
        xEnd = xMax if xEnd > xMax
        [xBegin, xEnd]

    organizeToSlots = (entries) ->

        # This is O(N^2) w.r.t. the number of entries

        # each slot is initialized as an empty array
        slots = _.map(_.range(N_ROWS-1), (i) -> [])

        # two passes over all entries, first "priority" entries (non-patient
        # input) and then non-priority entries (TreatmentEntries)
        for priority in [true,false]

            for entry in entries when entry.priority == priority

                # find the first slot where the entry does not intersect
                # any existing entries (intervals) in that slot

                freeSlot = -1

                for others, index in slots
                    unless _.some(others, (e) -> hasIntersection(e,entry))
                        freeSlot = index
                        break

                if freeSlot < 0
                    # if none found, assign to the last slot, whose overlapping
                    # entries are combined later
                    entry.slot = N_ROWS-1
                else
                    entry.slot = freeSlot
                    slots[freeSlot].push entry

        newEntries = []

        # combine overlapping entries in the last slot and also push all
        # remaining (not combined) entries to the newEntries array, preserving
        # original order
        comboEntry = null
        for entry in entries
            if entry.slot == N_ROWS-1
                if comboEntry && hasIntersection(entry,comboEntry)
                    entry.count = 1 unless entry.count
                    comboEntry.count += entry.count
                    comboEntry.color = COMBO_ENTRY_COLOR unless _.isEqual(comboEntry.color,entry.color)
                    comboEntry.text = "(#{comboEntry.count} #{I18n.t('js.calendars.graphs.n_more_items')})"
                    comboEntry.isPoint = false
                    comboEntry.xEnd = entry.xEnd if entry.xEnd > comboEntry.xEnd
                    comboEntry.priority = false
                    continue
                else
                    comboEntry = entry
                    comboEntry.count = 1

            newEntries.push entry

        newEntries

    hasIntersection = (e1,e2) ->
        hasTextIntersection(e1,e2) || hasBarIntersection(e1,e2)

    hasTextIntersection = (e1, e2) ->
        !(e2['xText'] > (e1['xText']+e1['textWidth']+TEXT_PADDING) || (e2['xText']+e2['textWidth']+TEXT_PADDING) < e1['xText'])

    hasBarIntersection = (e1, e2) ->
        !(e2.xBegin >= e1.xEnd || e2.xEnd <= e1.xBegin)

    getDrawingPositions = (plot, ctx, data) ->

        # TODO: this is called twice with the same data:
        # in draw and drawBackground.

        entries = []
        xaxis = plot.getAxes().xaxis

        lastPoint = null

        for entry in data

            # patient input data is "low-priority", which is shown if there
            # is space left from priority items
            priority = entry.type != "TreatmentEntry"

            # low-priority items are hidden from staff
            continue if !priority && User.type != "Client"

            title = entry.graph_title

            xBegin = xaxis.p2c(entry.begin_at_ms)

            if entry.duration
                xEnd = xaxis.p2c(entry.begin_at_ms + entry.duration * 1000)

            isPoint = !entry.duration || xEnd - xBegin <= MIN_BAR_WIDTH
            xEnd = xBegin if isPoint

            visible = visiblePart(plot,xBegin,xEnd)
            continue if visible == null
            xBegin = visible[0]
            xEnd = visible[1]

            if priority
                color = PRIORITY_ENTRY_COLOR
            else
                color = PATIENT_ENTRY_COLOR

            merged = isPoint && lastPoint != null && xBegin < lastPoint.xBegin + MIN_BAR_WIDTH

            if merged
                # combine close points
                lastPoint.count += 1
                merged = true
                xBegin = lastPoint.xBegin
                xEnd = lastPoint.xEnd
                unless _.isEqual(color,lastPoint.color)
                    priority = true
                    color = COMBO_ENTRY_COLOR

                if lastPoint.title == title
                    text = "#{title} (x#{lastPoint.count})"
                else
                    if lastPoint.count > 2
                        text = "#{lastPoint.count} #{I18n.t('js.calendars.graphs.n_items')}"
                    else
                        text = "#{lastPoint.title} & #{title}"
            else
                timeFormat = "L"
                timeFormat += " LT" unless entry.date_only
                beginDate = moment(entry['begin_at']).format(timeFormat)
                text = beginDate
                if entry.end_at && entry.date_only
                    endDate = moment(entry['end_at']).format(timeFormat)
                    text += " - " + endDate unless endDate == beginDate
                text += " " + title

            textMeasures = ctx.measureText(text)
            xText = xBegin + TEXT_PADDING
            if xText + textMeasures.width + TEXT_PADDING > actualPlotWidth(plot)
                xText = actualPlotWidth(plot) - textMeasures.width - TEXT_PADDING

            entry = {
                title: title,
                text: text,
                xText: xText,
                textWidth: textMeasures.width,
                textHeight: textMeasures.height,
                xBegin: xBegin,
                xEnd: xEnd,
                isPoint: isPoint,
                priority: priority
                color: color
            }

            if merged
                _.extend(lastPoint, entry)
            else
                entries.push(entry)

                if isPoint
                    lastPoint = entry
                    lastPoint.count = 1

        entries = organizeToSlots(entries)

        actualHeight = plot.height() + plot.getPlotOffset().top
        for e in entries
            e.yPos = actualHeight - e.slot * ROW_HEIGHT - BAR_HEIGHT - PADDING_BOTTOM

        entries

    arrayToColor = (color, alpha) ->
        rgb = "#{color[0]},#{color[1]},#{color[2]}"
        if alpha == null
            "rgb(#{rgb})"
        else
            "rgba(#{rgb},#{alpha})"

    blendToWhite = (color, amount) ->
        arrayToColor(_.map(color, (c) -> Math.round(255 - (255-c)*(1.0-amount))),null)

    drawXTicks = (plot,ctx,existingLines) ->

        # draw x tick lines manually, skipping the ones that are too close
        # to the other vertical lines drawn earlier

        # (this is not done using the flot API, be careful if flot is updated...)

        xaxis = plot.getAxes().xaxis
        xaxis.show = false

        x0 = plot.getPlotOffset().left
        x1 = x0 + plot.width()

        existingLines = _.sortBy(existingLines, (n) -> n)

        # Minimum space between grid line and other vertical lines in pixels
        MIN_LINE_SEP = 50

        ctx.strokeStyle = xaxis.options.tickColor
        ctx.beginPath()
        for tick in xaxis.ticks
            x = xaxis.p2c(tick.v)
            continue if x < x0 || x > x1

            while existingLines.length > 0 && existingLines[0] <= x - MIN_LINE_SEP
                existingLines.shift()

            continue if existingLines.length > 0 && Math.abs(existingLines[0]-x) < MIN_LINE_SEP

            #ctx.fillRect(x, plot.getPlotOffset().top, 1, plot.height())
            ctx.moveTo(x,plot.getPlotOffset().top)
            ctx.lineTo(x,plot.getPlotOffset().top+plot.height())
        ctx.stroke()


    drawBackground = (plot, ctx) ->

        entries = plot.getOptions().entries.data
        xaxis = plot.getAxes().xaxis
        entries = getDrawingPositions(plot, ctx, entries)
        entryLines = []

        y0 = plot.getPlotOffset().top
        h = plot.height()

        # background fill
        for priority in [false,true]
            for entry in entries when entry.priority == priority && !entry.isPoint
                ctx.fillStyle = blendToWhite(entry.color,0.85)
                ctx.fillRect(entry.xBegin, y0, entry.xEnd - entry.xBegin, entry.yPos-y0)

        # lines for point entries and endpoints
        for entry in entries
            if entry.isPoint
                LINE_WIDTH = 2
                ctx.fillStyle = arrayToColor(entry.color, 0.5)
                ctx.fillRect(entry.xBegin - (LINE_WIDTH-1)/2, y0, LINE_WIDTH, entry.yPos-y0)
            else
                ctx.strokeStyle = blendToWhite(entry.color,0.7)
                ctx.strokeRect(entry.xBegin, y0, entry.xEnd - entry.xBegin, entry.yPos-y0)
                entryLines.push entry.xEnd
            entryLines.push entry.xBegin

        drawXTicks(plot,ctx,entryLines)


    draw = (plot, ctx) ->
        entries = plot.getOptions().entries.data
        xaxis = plot.getAxes().xaxis
        entries = getDrawingPositions(plot, ctx, entries)

        # draw bars
        for entry in entries

            ctx.fillStyle = arrayToColor(entry.color, null)
            ctx.fillText(entry['text'], entry['xText'], entry.yPos-TEXT_PADDING)

            if entry.isPoint
                POINT_SZ = 4
                ctx.strokeStyle = ctx.fillStyle
                ctx.strokeRect(entry.xBegin - POINT_SZ/2, entry.yPos, POINT_SZ, POINT_SZ)
            else
                ctx.fillStyle = arrayToColor(entry.color, 0.5)
                ctx.fillRect(entry.xBegin, entry.yPos, entry.xEnd - entry.xBegin, BAR_HEIGHT)


    processOptions = (plot, options) ->
        if options.entries && options.entries.show
            # disable background
            options.grid.backgroundColor = null

            plot.hooks.drawBackground.push(drawBackground)
            plot.hooks.draw.push(draw)

    plot.hooks.processOptions.push(processOptions)

$.plot.plugins.push({
    init: init,
    name: 'calendar-entries',
    version: '0.0.1'
})
