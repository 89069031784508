jQuery ->
    # trigger a callback based on a time attribute
    data_timer = (data_attr, call_back) ->
        $('[data-'+data_attr+']').each ->
            elem = $(this)
            # get the time (in seconds) from the data attribute
            time = parseInt(elem.data data_attr) * 1000

            if time >= 0
                setTimeout ->
                    call_back(elem)
                , time

    $('#session_person_id').on 'change', ->
        email_length = $('#session_person_id').val().length
        if (email_length > 0)
            $('#session_person_id').attr('aria-invalid', false)
            $('#email-error-message').addClass('hide-error')
            $('#session_person_id').addClass('clear-input')

    $('#session_person_id').on 'blur', ->
        email_length = $('#session_person_id').val().length
        if (email_length < 1)
            $('#email-error-message').removeClass('hide-error')
            $('#session_person_id').removeClass('clear-input')
            $('#session_person_id').attr('aria-invalid', true)

    $('#sms-input').on 'focus', ->
        $('#sms-input').attr('aria-invalid', false)
        $('#sms-input').addClass('clear-input')

    # Prevent resend/submit until timer runs out
    $('#resend-code-button').click (event)->
        link_disabled = $('.resend-disabled-link').length
        if (link_disabled)
            event.preventDefault()

    enableSendNewCode = ->
        btn = $('#resend-code-button')
        btn.removeClass('disabled')
        btn.removeAttr('disabled')
        btn.removeAttr('aria-disabled')
        btn.removeAttr('aria-describedby')
        btn.removeClass('resend-disabled-link')

    update_countdown = (elem, target_time) ->
        time_remaining = (target_time - new Date()) / 1000

        if (elem.data('text-template'))
            elem.text I18n.t(elem.data('text-template'),
                { time: new Date(time_remaining * 1000).toISOString().substr(14, 5); })
        else
            elem.text(time_remaining)

        if (time_remaining > 0)
            setTimeout ->
                update_countdown elem, target_time
            , 1000
        else
            elem.hide()
            enableSendNewCode()

    # set timers according to data-attributes in dom-elements
    data_timer 'enable-in', (elem) ->
        elem.add(elem.find('*')).removeClass('disabled')
        elem.add(elem.find('*')).removeAttr('disabled')
        elem.add(elem.find('*')).removeAttr('aria-disabled')

    data_timer 'show-in', (elem) ->
        elem.show(300)

    data_timer 'hide-in', (elem) ->
        elem.hide(300)

    # count-down elements update the innerHTML and disappear when they reach 0
    $('[data-count-down-from]').each ->
        count_down_seconds = parseInt($(this).data('count-down-from'))
        if (!isNaN(count_down_seconds))
            target_time = moment(new Date()).add(count_down_seconds, 's').toDate()
            update_countdown($(this), target_time)